import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
//import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
//import {faAt, faPhoneSquareAlt, faEnvelope} from "@fortawesome/free-solid-svg-icons";*/}
//import {faFacebookSquare} from "@fortawesome/free-brands-svg-icons";*/}
import logo from '../../static/Emblem.png'

const Contact = () => {
  return (
    <Layout headerClass="bg-white relative">
      <SEO title="Contact" />
      <div className="md:flex flex-col md:flex-row md:min-h-screen w-full">
        <div className="flex flex-col w-full">
          <header>
          <div className="markdown-body mt-6">
            <h1>Contact Us</h1>
          </div>
          </header>
          <main>
          <div className="flex-shrink-0 py-0 flex flex-col md:flex-row mt-4">
            <div className="w-full md:w-3/5">
              <div className="markdown-body">
                <div className="gmap_canvas" style={{ width: '100%', height: '100%' }}>
                  <iframe
                    width="100%"
                    height="300"
                    id="gmap_canvas"
                    title={'Burton Map'}
                    src="https://maps.google.com/maps?q=24%20high%20street,%20burton%20upon%20stather,%20DN15%209DE&t=&z=19&ie=UTF8&iwloc=&output=embed"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                  ></iframe>
                </div>
              </div>
              <div className="w-full">
                <h2 className="mt-10 text-2xl">Clerk Availability</h2>
                <div className="flex border-t h-10">
                  <div className="font-semibold flex-initial w-5/12">Monday</div>
                  <div className="text-center flex-initial w-3/12">9:00 am</div>
                  <div className="text-center flex-initial w-1/12">-</div>
                  <div className="text-center flex-initial w-3/12">1:00 pm</div>
                </div>
                <div className="flex border-t h-10">
                  <div className="font-semibold flex-initial w-5/12">Tuesday</div>
                  <div className="text-center flex-initial w-3/12">9:00 am</div>
                  <div className="text-center flex-initial w-1/12">-</div>
                  <div className="text-center flex-initial w-3/12">1:00 pm</div>
                </div>
                <div className="flex border-t h-10">
                  <div className="font-semibold flex-initial w-5/12">Wednesday</div>
                  <div className="text-center flex-initial w-3/12">9:00 am</div>
                  <div className="text-center flex-initial w-1/12">-</div>
                  <div className="text-center flex-initial w-3/12">1:00 pm</div>
                </div>
                <div className="flex border-t h-10">
                  <div className="font-semibold flex-initial w-5/12">Thursday</div>
                  <div className="text-center flex-initial w-3/12">9:00 am</div>
                  <div className="text-center flex-initial w-1/12">-</div>
                  <div className="text-center flex-initial w-3/12">1:00 pm</div>
                </div>
              </div>
            </div>
            <div
              className="w-full md:w-2/5 md:pl-6 mb-4 md:mb-0 text-right p-4 z-40 md:ml-6 bg-gray-700 rounded-r-lg md:rounded-r-none rounded-l-lg text-white inverse float-right inline-block inset-0">
              <img src={logo} alt={'Burton upon stather'} className={'float-right mb-8'}/>
              <p className={'clear-both block'}>The Parish Clerk is available as follows:</p>
              <p>
                <a href={'tel:+441724489981'}>
                  {/*<FontAwesomeIcon icon={faPhoneSquareAlt} size="sm" /> :*/} 01724 489981
                </a>
                <br />
                <a href={'mailto:clerk@burtonuponstatherparishcouncil.gov.uk'}>
                  {/*<FontAwesomeIcon icon={faAt} size={'sm'} /> :*/} clerk@burtonuponstatherparishcouncil.gov.uk
                </a>
                <br />
                <a href={'https://www.facebook.com/Burton-upon-Stather-Parish-Council-674391389650533'}>
                  {/*<FontAwesomeIcon icon={faFacebookSquare} size={'sm'} /> :*/} Burton PC facebook group
                </a>
              </p>
              <address>
                {/*<FontAwesomeIcon icon={faEnvelope} size={'sm'} /> :*/}
                High Street, Burton upon Stather, DN15 9DE
              </address>
            </div>
          </div>
          </main>
        </div>
      </div>
    </Layout>
  )
};

export default Contact;
